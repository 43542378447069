import { useState, useEffect, useContext, useRef } from 'react'
import Category from '../components/Category'
import axios from 'axios'
import sevoryStyle from "./servory.module.css";
import { useNavigate, useParams } from 'react-router-dom';
import LoaderTailSpin from '../components/LoaderTailSpin';

export default function Servory() {

    const firstCtegory = "62024b02210086314185dc7b"
    const { id } = useParams();
    const navigate = useNavigate()
    const [categories, setCategories] = useState()
    const [disabledBtn, setDisabledBtn] = useState(true)
    const [disabled, setDisabled] = useState([true, false])
    const [answers, setAnswers] = useState({})
    const [completed, setCompleted] = useState(0)
    const [categoryQ, setCategoryQ] = useState(firstCtegory)
    const correntCategory = useRef({})
    const categoryBox = useRef({})
    const submitInfo = useRef()
    const submitBtn = useRef()

    useEffect(() => {
        let answersNumber = Object.keys(answers).length
        setCompleted(((answersNumber / 40) * 100).toFixed(1))
        if (answersNumber === 40) {
            setDisabledBtn(false)
            submitBtn.current.style.background = "rgb(110, 171, 246)"
            submitBtn.current.style.cursor = "pointer"
        }
    }, [answers])

    // const timer1 = () => {

    // }

    const containerStyles = {
        height: '20px',
        width: '80%',
        backgroundColor: 'rgb(224, 224, 222)',
        borderRadius: '50px',
        margin: '13px auto 0px auto'
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: "#9de4c6",
        borderRadius: 'inherit',
        textAlign: 'right'
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    const btnStyle = {
        cursor: "pointer",
        borderRadius: '50%',
        backgroundColor: '#9de4c6',
        color: 'white',
        textDecoration: 'none',
        padding: '8px 16px',
        margin: '1rem',
        fontWeight: 'bold',
        fontSize: '2em',
        height: '1.5em',
        display: 'flex',
        width: '1.5em',
        alignItems: 'center',
        justifyContent: 'center'
    }

    useEffect(async () => {
        if (!id)
            navigate("/register")
        axios.get('https://urbanimpacttool.herokuapp.com/Categories')    //http://localhost:3100
            .then(result => {
                setCategories(result.data)
            })
        let user = await axios.get(`https://urbanimpacttool.herokuapp.com/user?ProjectiD=${id}`)
        user = user.data.resulte
        if (Object.keys(user.answers).length > 0) {
            setAnswers(user.answers)
            let amount = Object.keys(user.answers).length;
            if (amount === 40) {
                let resulte = await axios.put('https://urbanimpacttool.herokuapp.com/done?ProjectiD=' + id, { answers })
                if (resulte.data.resulte.id) {
                    navigate(`/certificate/${id}`)
                }
            }
        }
        // ליבא את הסקר, לבדוק אם הוא גמור ואם לא למלא את התשובות
        alert(`לפני מעבר לשאלון עצמו שימו לב
    כי במידה ואינכם יודעים את התשובה לאחת השאלות
    תוכלו לשוב לשאלון עם מספר השאלון שנשלח במייל.
    זמן מילוי הטופס מוערך ב- 45 דקות.
    `)
    }, [])

    const moveToNextCategory = () => {
        let keys = Object.keys(correntCategory.current);
        let nextIndex = keys.indexOf(categoryQ) + 1;
        if (nextIndex <= 9) {
            let nextItem = keys[nextIndex];
            changeCategory(nextItem)
        }
    }
    const moveToPreviousCategory = () => {
        let keys = Object.keys(correntCategory.current);
        let previousIndex = keys.indexOf(categoryQ) - 1;
        if (previousIndex >= 0) {
            let previousItem = keys[previousIndex];
            changeCategory(previousItem)
        }
    }


    const changeCategory = (id) => {
        window.scrollTo(0, 0)
        if (id === 0) setDisabled([true, false])
        else if (id === 9) setDisabled([false, true])
        else setDisabled([false, false])
        correntCategory.current[categoryQ].style.display = "none"
        categoryBox.current[categoryQ].style.opacity = "0.5"
        setCategoryQ(id)
        correntCategory.current[id].style.display = "block"
        categoryBox.current[id].style.opacity = "1"
    }
    const saveProcess = async () => {
        let resulte = await axios.put('https://urbanimpacttool.herokuapp.com/save?ProjectiD=' + id, { answers })
        if (resulte.status !== 200) {
            submitInfo.current.style.color = 'red'
            submitInfo.current.innerText = 'בעיה בשמירת התשובות לסקר, אנא נסה שנית.'
        }
        else {
            console.log('נשמר')
            submitInfo.current.style.color = 'black'
            submitInfo.current.innerText = 'התשובות נשמרו בהצלחה.'
        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (Object.keys(answers).length === 40) {
            submitInfo.current.style.color = 'black'
            submitInfo.current.innerText = 'בקשת השליחה התקבלה.'
            let resulte = await axios.put('https://urbanimpacttool.herokuapp.com/done?ProjectiD=' + id, { answers })
            if (resulte.data.status === 200) {
                navigate(`/certificate/${id}`)
            } else {

                submitInfo.current.style.color = 'red'
                submitInfo.current.innerText = 'בעיה בשליחת הסקר, אנא נסה שנית.'
                // alert('הטופס לא נשלח, נא נסה שנית יותר מאוחר') 
            }
        }
        // else alert('יש לענות על כל השאלות')
    }



    const handleChange1 = (event) => {
        let name = event.target.name
        let value = event.target.value
        setAnswers({ ...answers, [name]: value })
    }

    const handleChange2 = (event) => {
        let name = event.target.name
        let value = event.target.value
        let arrAns = answers[name] || []
        if (event.target.checked)
            arrAns.push(value)
        else {
            let index = arrAns.findIndex(el => el === value)
            if (index !== -1)
                arrAns.splice(index, 1)
        }
        setAnswers({ ...answers, [name]: [...arrAns] })
    }

    if (categories)
        return <div>
            <div className={sevoryStyle.categoriesLine}>
                {categories.map(val => {
                    return <Category onClick={changeCategory} key={val.icon[0].altText} category={val} >
                        <img src={val.icon[0].imageURL} style={(val._id === firstCtegory) ? {} : { opacity: "0.5" }}
                            ref={el => categoryBox.current[val._id] = el} />
                    </Category>
                })}
            </div>
            <div style={containerStyles}>
                <div style={fillerStyles}>
                    <span style={labelStyles}>{`${completed}%`}</span>
                </div>
            </div>
            <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '1.2rem' }} ref={submitInfo}></div>
            <form className={sevoryStyle.formQuestion} onSubmit={handleSubmit}>
                <div style={{ display: 'flex', placeContent: 'center', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '1.5rem', marginRight: '1rem' }}>מזהה הסקר שלך הוא: {id}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input type="button" key="save" onClick={saveProcess} style={{ margin: '0.5rem 0.5rem 0.5rem 0.5rem', fontFamily: 'Assistant-Bold, Assistant', fontSize: '24px', borderRadius: '50px', border: 'none', background: 'rgb(110, 171, 246)', color: 'rgb(255, 255, 255)', padding: '7px 31px', cursor: 'pointer' }} value="שמור" />
                        <input type="submit" key="done" ref={submitBtn} disabled={disabledBtn} onClick={handleSubmit} style={{ margin: '0.5rem 0.5rem 0.5rem 0.5rem', fontFamily: 'Assistant-Bold, Assistant', fontSize: '24px', borderRadius: '50px', border: 'none', background: 'rgb(110 171 246 / 52%)', color: 'rgb(255, 255, 255)', padding: '7px 31px', cursor: 'not-allowed' }} value="שלח" />
                    </div>
                </div>
                {categories.map((val, i) => {
                    return <div className='categoryQuestions' ref={el => correntCategory.current[val._id] = el}
                        style={(val._id === firstCtegory) ? {} : { display: "none" }} >
                        <Questions categoryID={val._id} key={val._id} handleChange1={handleChange1} answers={answers} handleChange2={handleChange2} />
                    </div>
                })
                }
            </form>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <button onClick={moveToPreviousCategory} style={btnStyle} disabled={disabled[0]}>&#8249;</button>
                <button onClick={moveToNextCategory} style={btnStyle} disabled={disabled[1]} >&#8250;</button>
            </div>
        </div>
    else
        return <div>מעלה סקר</div>

}

function Questions({ categoryID, handleChange1, handleChange2, answers }) {
    const [questions, setQuestions] = useState([])

    useEffect(correntCategory, [])

    function correntCategory() {
        axios.get(`https://urbanimpacttool.herokuapp.com/Questions?category=${categoryID}`) //https://urbanimpacttool.herokuapp.com
            .then(result => {
                setQuestions(result.data)
            })
            .catch((err) => console.log(err))
    }


    if (questions)
        return <>
            {questions.map(question => {
                console.log();
                return <div style={{ background: "white", margin: "15px", padding: "4px" }}>
                    <div className='qustion' style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ margin: "0px 1px", minWidth: "80px", fontSize: "1.3rem" }}> שאלה {question.order}</span>
                        <h4 style={{ margin: "8px 3px 8px 8px" }} dangerouslySetInnerHTML={{ __html: question.question }} />
                        {(question.type.value === "multi_chose") ? <p>*בשאלה זו ניתן לבחור מספר תשובות</p> : ''}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "8px" }}>
                        {(question.type.value === "single") ? question.answers.map(answer => {
                            let checked = Boolean((question._id in answers) && (answers[question._id] === answer._id))
                            return <label onChange={handleChange1} style={{ marginBottom: "10px", display: "flex", alignItems: "center" }} ><span style={{ minWidth: "65px", fontSize: "12px", display: "inline-block", textAlign: "center" }}>{answer.score} נק'</span>
                                <input key={answer._id} type="radio"
                                    value={answer._id}
                                    name={question._id}
                                    defaultChecked={checked}
                                    required /> {answer.answer}
                            </label>
                        }) : question.answers.map(answer => {
                            let checked = Boolean((question._id in answers) && (answers[question._id] === answer._id))
                            return <label required style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}><span style={{ minWidth: "65px", fontSize: "8px", display: "inline-block", textAlign: "center" }}>{answer.score} נק'</span>
                                <input key={answer._id} type="checkbox"
                                    value={answer._id}
                                    onChange={handleChange2}
                                    defaultChecked={checked}
                                    name={question._id} /> {answer.answer}
                            </label>
                        })}
                    </div>
                </div>
            })}
        </>
    else return <LoaderTailSpin />
}



