import axios from "axios"
import { useState, useRef, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import RegisterStyle from './Register.module.css'


export default function Login() {
    const [data, setData] = useState({ email: '', ProjectiD: '' })
    const navigate = useNavigate()

    const submitInfo = useRef()
    const handleSubmit = async (event) => {
        event.preventDefault();

        submitInfo.current.style.color = 'black'
        submitInfo.current.innerText = 'הנתונים התקבלו נשלחו לבדיקה.'
        let resulte = await axios.post('https://urbanimpacttool.herokuapp.com/login', { data }) //http://localhost:3100
        console.log(resulte);
        if (resulte.status !== 200) {
            submitInfo.current.style.color = 'red'
            submitInfo.current.innerText = 'בעיה בשליחת הטופס, אנא בדוק את הנתונים שהזנת ושלח שוב.'
        }
        else {
            sessionStorage.setItem("ServoryID", resulte.data.projectiD)
            let url = `/servory/${resulte.data.projectiD}`
            navigate(url)
        }
    }

    const handleChange = (event) => {
        let name = event.target.name
        let value = event.target.value
        setData({ ...data, [name]: value })
    }

    return <> <form onSubmit={handleSubmit} className={RegisterStyle.formRegister}>
        <h2>התחבר או <NavLink to={'/register'}> הירשם</NavLink> </h2>
        <label className={RegisterStyle.inputBox}>
            <input type="email" name="email" value={data.email} placeholder="Email" onChange={handleChange} required />
        </label>
        <label className={RegisterStyle.inputBox}>
            <input type="number" name="ProjectiD" minLength="6" maxLength="6" value={data.ProjectiD} placeholder="מזהה סקר" className={RegisterStyle.projectiD} onChange={handleChange} required />
        </label>
        <input className={RegisterStyle.submit} type="submit" value="התחבר" />
    </form>
        <div style={{ fontWeight: 'bold', fontSize: '1.3rem', textAlign: 'center' }} ref={submitInfo}></div>
    </>
}