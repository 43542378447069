import axios from 'axios'
import { useState, useEffect } from 'react'
import PdfFile from '../components/PdfFile'
import { useParams } from 'react-router-dom';
import LoaderAudio from '../components/LoaderAudio';


export default function Certificate() {

    const [finish, setFinish] = useState(false)
    const [info, setInfo] = useState({})
    const [stickers, setStickers] = useState()
    const [score, setScore] = useState()
    const [totualScor, setTotualScor] = useState()
    const [user, setUser] = useState()
    const [categories, setCategories] = useState()
    const { id } = useParams();


    useEffect(firstRun, [])
    useEffect(() => { if (stickers && score && totualScor && categories && user) { setFinish(true) } })
    useEffect(setUserInfo, [user])
    useEffect(setScores, [score, totualScor])


    function setScores() {
        if (score) {
            let sum = Math.round(score.reduce((tmpSum, val) => tmpSum + val, 0));
            setInfo({ ...info, score: sum })
        }
        if (totualScor) {
            let totual = totualScor.reduce((tmpSum, val) => tmpSum + val, 0)
            setInfo({ ...info, totual: totual })
        }
    }

    function setUserInfo() {
        if (user)
            setInfo({
                ...info, name: user.companyName,
                address: `${user.address.street} ${user.address.entrance} ${user.address.city}`,
                currentUnits: user.currentUnits,
                isPeriphery: (user.isPeriphery) ? 'כן' : 'לא',
                longTermUnits: user.longTermUnits,
                projectDescription: user.projectDescription,
                projectStat: user.projectStat,
                projectType: user.projectType.value,
                proposeUnits: user.proposeUnits,
                reachUnits: user.reachUnits,
                peripheryScor: (user.isPeriphery) ? 10 : 0
            })
    }

    async function firstRun() {
        let { data, status } = await axios.get(`https://urbanimpacttool.herokuapp.com/user?ProjectiD=${id}`)
        // if (!data.resulte.isDone)
        //     navigate(`/servory/${id}`)
        let result = await axios.get('https://urbanimpacttool.herokuapp.com/Categories')    //http://localhost:3100
        setCategories(result.data)
        if (status == 200) {
            let resulte = data.resulte
            setUser(resulte)
            let res = await axios.get(`https://urbanimpacttool.herokuapp.com/Certificate?ProjectiD=${id}`)
            setScore(res.data.score)
            setStickers(res.data.stickers)
            setTotualScor(res.data.totualScor)
        }
    }


    if (finish) {
        return <PdfFile stickers={stickers} info={info} score={score} totualScor={totualScor} categories={categories} />
    }
    else return <div style={{ display: "flex", justifyContent: "center" }}>
        <h2>ברגעים אלו אנחנו מבצעים את החישוב ומיד דוח הסיכום יופיע פה וישלח אליך למייל</h2>
        <LoaderAudio />
    </div>

}




