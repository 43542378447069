import Sticker from '../components/Sticker'
import ProgresBar from './ProgresBar'
import Impect from '../SVG/impect.svg'
import sign from '../SVG/sign.svg'
import A from '../SVG/A.svg'
import B from '../SVG/B.svg'
import C from '../SVG/C.svg'
import D from '../SVG/D.svg'
import E from '../SVG/E.svg'

export default function PdfFile({ stickers, info, score, totualScor, categories }) {
    const colorSet = ["#9de4c6", "#91d7ff", "#b7a6db", "#c7e358", "#ff9f5e", "#ffe55f", "#6eabf6", "#eaa9d2", "#77bf75", "#b4bec3"]

    return <div id="pdfFile" style={{ fontFamily: 'Assistant-Bold, Assistant', margin: '0px auto', color: 'rgb(53, 82, 121)', direction: 'rtl', fontSize: '18px' }}>
        <div className='head' style={{ display: 'flex', backgroundColor: 'rgb(53, 82, 121)', justifyContent: 'space-between', alignItems: 'center', height: '7rem', padding: '0px 3%', margin: '1rem 0px' }}>
            <h1 style={{ color: "#ffffff" }}>דו"ח סיכום הערכת אימפקט</h1>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Impect} style={{ height: "5rem" }} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginRight: '0.8rem' }}>
                    <h2 style={{ color: "white", margin: '0px' }}>כלי להערכת אימפקט</h2>
                    <h3 style={{ color: "white", margin: '0px' }}>של מיזמי התחדשות עירונית</h3>
                </div>
            </div>
        </div>
        <div style={{ backgroundSize: 'auto', backgroundRepeat: 'no-repeat', display: 'flex', justifyContent: 'space-evenly', padding: '0px 1rem', width: "80%", margin: "0px auto" }}>
            <div className='block1' style={{ width: '65%', marginLeft: '3rem' }}>
                <div className='grade' style={{ marginBottom: '10px', display: 'flex', backgroundColor: 'rgb(243, 243, 243)', alignItems: 'center', paddingRight: '1rem' }}>
                    <Garde score={info.score + info.peripheryScor} />
                </div>
                <div className='categories' style={{ height: "33em", display: "flex", justifyContent: 'space-around' }}>
                    {score.map((val, i) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '9%' }}>
                            <div style={{ backgroundImage: `url(${categories[i].icon[0].imageURL})`, height: '7.5rem', width: '5.5rem', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}></div>
                            <ProgresBar bgcolor={colorSet[i]} score={score[i]} totualScor={totualScor[i]} />
                            <span style={{ textAlign: 'center' }}><span style={{ fontWeight: "bold", color: colorSet[i] }}>{score[i].toFixed(1)}</span> <br /> מתוך {totualScor[i]}</span>
                        </div>
                    })}
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                    <div style={{ textAlign: "center", backgroundColor: "#e6e6e6", padding: "0.8rem", margin: "0.2rem 0px 0.2rem 0.2rem", width: "90%" }}><span style={{ fontWeight: "bold" }}>סה"כ ניקוד: {info.score}</span> מתוך {info.totual}</div>
                    <div style={{ textAlign: "center", backgroundColor: "#e6e6e6", padding: "0.8rem", margin: "0.2rem 0px 0.2rem 0px", width: "90%" }}><span style={{ fontWeight: "bold" }}>מקדם פריפריה: {info.peripheryScor}</span> מתוך 10</div>
                </div>
                <div style={{ textAlign: "center", backgroundColor: "#e6e6e6", padding: "0.8rem" }}><span style={{ fontWeight: "bold" }}>ציון סופי: {info.score + info.peripheryScor}</span> מתוך 110</div>
            </div>
            <div className='block2' style={{ width: '44.5%' }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tr>
                        <td style={{ border: '1px solid rgb(0, 0, 0)', width: '50%' }}>שם החברה</td>
                        <td style={{ border: "1px solid #000000" }}>{info.name}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid rgb(0, 0, 0)', width: '50%' }}>כתובת</td>
                        <td style={{ border: "1px solid #000000" }}>{info.address}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid rgb(0, 0, 0)', width: '50%' }}>סוג הפרויקט</td>
                        <td style={{ border: "1px solid #000000" }}>{info.projectType}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid rgb(0, 0, 0)', width: '50%' }}>האם הפרויקט ממוקם בפריפריה הגיאוגרפית-חברתית?</td>
                        <td style={{ border: "1px solid #000000" }}>{info.isPeriphery}</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid #000000" }}>יחידות דיור במצב קיים</td>
                        <td style={{ border: "1px solid #000000" }}>{info.currentUnits}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid rgb(0, 0, 0)', width: '50%' }}>יחידות דיור במצב המוצע</td>
                        <td style={{ border: "1px solid #000000" }}>{info.proposeUnits}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid rgb(0, 0, 0)', width: '50%' }}>יחידות דיור בהישג יד במצב המוצע</td>
                        <td style={{ border: "1px solid #000000" }}>{info.reachUnits}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid rgb(0, 0, 0)', width: '50%' }}>חידות דיור לשכירות ארוכת טווח במצב המוצע</td>
                        <td style={{ border: "1px solid #000000" }}>{info.longTermUnits}</td>
                    </tr>
                </table>
                <div style={{ margin: '10px 0px', border: '1px solid rgb(0, 0, 0)', padding: '0.3rem 0.8rem' }}>
                    <h5>השלב בו מצוי הפרויקט</h5>
                    <p>{info.projectStat}</p>
                </div>
                <div style={{ margin: '10px 0px', border: '1px solid rgb(0, 0, 0)', padding: '0.3rem 0.8rem', height: "17vh" }}>
                    <h5>תיאור הפרויקט</h5>
                    <p>{info.projectDescription}</p>
                </div>
                <div style={{ margin: '10px 0px', border: '1px solid rgb(0, 0, 0)', padding: '0.3rem 0.8rem' }}>
                    <h5>יעדים ברי-קיימא של האו״ם (SDGs UN (שבאים לידי ביטוי בפרויקט:</h5>
                    <div style={{ display: "flex", justifyContent: 'space-around' }}>{
                        stickers.map(sticker => {
                            return <Sticker sticker={sticker} key={sticker} />
                        })}</div>
                </div>
                <div className='small' >ההערכה בדו״ח זה מבוססת על הכלי להערכת אימפקט של מיזמי התחדשות עירונית. הכלי בוחן קריטריונים חברתיים וסביבתיים
                    מקובלים בארץ ובעולם להערכת אימפקט של מיזמי התחדשות עירונית. הכלי פותח על ידי הגר למחקר ופיתוח מדיניות דיור חברתי
                    באוניברסיטת תל אביב ועל ידי Israel Finance Social</div>
                <hr style={{ marginRight: '0rem' }} ></hr>
                <img src={sign} style={{ width: '97.5%', }} />
            </div>
        </div>
    </div >
}

function Garde({ score }) {
    if (score > 90) {
        return <>
            <img src={A} style={{ height: "25%", width: "25%" }} />
            <div style={{ padding: "2em" }}>
                <h2>ציון כללי</h2>
                <p>הפרויקט מטמיע היבטים חברתיים וסביבתיים ברמה גבוהה ביותר</p>
            </div>
        </>
    } else if (score > 71) {
        return <>
            <img src={B} style={{ height: "25%", width: "25%" }} />
            <div style={{ padding: "2em" }}>
                <h2>ציון כללי</h2>
                <p>הפרויקט מטמיע היבטים חברתיים וסביבתיים ברמה גבוהה</p>
            </div>
        </>
    } else if (score > 52) {
        return <>
            <img src={C} style={{ height: "25%", width: "25%" }} />
            <div style={{ padding: "2em" }}>
                <h2>ציון כללי</h2>
                <p>הפרויקט מטמיע היבטים חברתיים וסביבתיים ברמה בינונית</p>
            </div>
        </>
    } else if (score > 34) {
        return <>
            <img src={D} style={{ height: "25%", width: "25%" }} />
            <div style={{ padding: "2em" }}>
                <h2>ציון כללי</h2>
                <p>הפרויקט מטמיע היבטים חברתיים וסביבתיים ברמה בסיסית</p>
            </div>
        </>
    } else {
        return <>
            <img src={E} style={{ height: "25%", width: "25%" }} />
            <div style={{ paddingRight: '3%', fontSize: '24px' }}>
                <h2 style={{ marginBottom: '0px' }}>ציון כללי</h2>
                <p>הפרויקט לא יוצר ערך מוסף משמעותי מדיד בסוגיות חברתיות וסביבתיות</p>
            </div>
        </>
    }

}