import './App.css';
import Layout from './layout/Layout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Register from './pages/Register'
import Login from './pages/Login'
import Guid from './pages/Guid'
import Info from "./pages/Info";
import Servory from './pages/Servory'
import Certificate from './pages/Certificate'


function App() {

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/info' element={<Info />} />
          <Route path='/guid' element={<Guid />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/servory/:id' element={<Servory />} />
          <Route path='/certificate/:id' element={<Certificate />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
