import Hguide from "../guides/כלי להערכת אימפקט התחדשות עירונית.pdf";
import Eguide from "../guides/Eng guide .pdf";
import backround from "../SVG/backround.svg"

export default function Guid() {
    const aStyle = {
        textDecoration: 'none',
        fontFamily: 'Assistant-Bold, Assistant',
        marginBottom: '15px',
        fontSize: '26px',
        borderRadius: '50px',
        border: '4.5px solid rgb(110, 171, 246)',
        background: 'rgb(255, 255, 255)',
        padding: '20px 30px',
        width: '13rem',
        textAlign: 'center',
        color: '#355279',
        fontWeight: '700'
    }

    const headline = {
        backgroundImage: `url(${backround})`,
        margin: '0px',
        height: '17rem',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '28px',
        fontWeight: '600',
        backgroundPositionY: 'bottom'
    }

    return <>
        <div style={headline}>
            <h1>המדריך לשימוש אופטימלי של הכלי עבור המיזם שלכם</h1>
        </div>
        <div style={{ fontFamily: "Assistant-Bold, Assistant", color: '#355279', fontSize: "26px", width: "60%", margin: "10px auto", display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ width: "50%" }}>
                <p>לפנייך מדריך אשר נועד לסייע במילוי השאלון. המדריך כולל הסבר מפורט על הכלי ורקע מורחב על השאלות המרכיבות את השאלון. ניתן למלא את השאלון גם ללא המדריך, אך אנו ממליצים לעיין במדריך ולהעזר בו במהלך מילוי השאלון באתר.</p>
                <p>בנוסף, המדריך עשוי להיות שימושי למי שרוצים להכיר טוב יותר את הכלי, את תהליך  העבודה ואת הרקע לשילוב בין השקעות אימפקט והתחדשות עירונית.</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <a href={Hguide} target="_blank" style={aStyle} download>להורדה בעברית</a>
                <a href={Eguide} target="_blank" style={aStyle} download>English Guide</a>
            </div>
        </div>
    </>
}