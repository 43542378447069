import Firstimg from "../SVG/firstimg.png";
import Secondimg from "../SVG/secondimg.png";
import Thirdimg from "../SVG/thirdimg.png";
import fourthimg from "../SVG/fourthimg.png";
import fifthimg from "../SVG/fifthimg.png";
import siximg from "../SVG/siximg.png";
import sevenimg from "../SVG/sevenimg.png";
import infoStyle from './info.module.css'
import backround from "../SVG/backround.svg"
import arrow from "../SVG/arrow.png"
import { useRef } from "react";

export default function Info() {

    const infoBox = useRef({})
    const arrowBox = useRef({})

    const box = (id) => {
        if (infoBox.current[id].style.height === '0px') {
            arrowBox.current[id].style.transform = 'rotate(180deg)'
            infoBox.current[id].style.height = 'auto'
        } else {
            arrowBox.current[id].style.transform = 'rotate(0deg)'
            infoBox.current[id].style.height = '0px'
        }
    }

    const headline = {
        backgroundImage: `url(${backround})`,
        margin: '0px',
        height: '17rem',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '28px',
        fontWeight: '600',
        backgroundPositionY: 'bottom',
        fontFamily: "Assistant-Bold, Assistant"
    }

    const collapseStyle = {
        MozTransition: 'height 1s',
        MsTransition: 'height 1s',
        WebkitTransition: 'height 1s',
        overflow: "hidden",
        height: '0px',
        transition: "height .5s"
    }
    return (<div style={{ fontFamily: "Assistant-Bold, Assistant" }}>
        <div style={headline}>
            <h1>בואו נכיר את הכלי</h1>
        </div>
        <div className={infoStyle.page}>
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>מה מטרת הכלי?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(0)} ref={el => arrowBox.current[0] = el} />
            </div>
            <p style={collapseStyle} ref={el => infoBox.current[0] = el}>
                הכלי להערכת אימפקט של מיזמי התחדשות עירונית הוא אמצעי לביצוע 'בדיקת נאותות חברתית וסביבתית' לפרויקטים של התחדשות עירונית, שנועדה להעריך את האימפקט של מיזמים מסוג זה. הכלי מיועד למשקיעים, ובפרט למשקיעי אימפקט, המעוניינים לבחון השפעה חברתית וסביבתית במסגרת שיקולי ההשקעה. הכלי נוצר כחלק ממהלך מתמשך לפיתוח שוק השקעות האימפקט בישראל. שוק האימפקט הולך ומתרחב בעולם ובמסגרתו משקיעים מבקשים לקבל לצד תשואה פיננסית על הון גם ערך חברתי או סביבתי מדיד.
            </p>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>איך פותח הכלי ועל ידי מי?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(1)} ref={el => arrowBox.current[1] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[1] = el}>
                <p>הכלי פותח במסגרת שיתוף פעולה בין Social Finance Israel - חברה לתועלת הציבור המקדמת כלכלת אימפקט בישראל ומרכז הגר אוניברסיטת תל אביב, העוסק במחקר ופיתוח דיור חברתי. הכלי פותח הודות לתמיכתם הנדיבה של קרן אדמונד רוטשילד ונעה יובל מעוז מקרן פרוסיד, במסגרת מענק לפיתוח האקוסיסטם ליזמות והשקעות  אימפקט, במאמץ משותף בין  ה-Jewish Funders Network  וקרן רוטשילד.</p>
                <p>הכלי להערכת אימפקט של מיזמי התחדשות עירונית מבוסס על שלושה מקורות מידע מרכזיים:</p>
                <div style={{ backgroundImage: `url(${Firstimg})`, height: "23em", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", backgroundSize: "contain" }}></div>
                <p>בניית הכלי נערכה בחמישה שלבים מרכזיים:</p>
                <div style={{ marginTop: "1.5rem", backgroundImage: `url(${Secondimg})`, height: "10em", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain" }}></div>
                <ol>
                    <li style={{ marginTop: "1.5rem" }}>גיבוש רשימת נושאים העוסקים בהשפעות חברתיות של התחדשות עירונית: תהליך זה התבסס על ניתוח של 37 ראיונות עם בעלי עניין.</li>
                    <li style={{ marginTop: "1.5rem" }}>הגדרת קטגוריות לבחינת ההשפעה של התחדשות עירונית בישראל: בשלב הזה איגדנו את נושאי ההשפעה מתחומים ועולמות תוכן נושקים תחת עשר קטגוריות מארגנות שמהוות את השלד הרעיוני של הכלי, כמפורט בתרשים בהמשך. בכל קטגוריה נבחרו 2-4 נושאים מרכזיים לבדיקה, בתהליך שהתבסס על ניתוח שכיחות בראיונות, סקירת ספרות, סקירת מדדים שעוסקים בהשפעות חברתיות של תהליכי פיתוח וכן ניתוח של הרגולציה הישראלית. ניתוח הרגולציה סייע לנו לבחון האם ישנם נושאים שאינם רלוונטיים למדידה כיוון שהם מתייחסים לחובות שמוטלות ממילא על מי שמבצע התחדשות עירונית.</li>
                    <li style={{ marginTop: "1.5rem" }}>פיתוח שאלות למדידת נושאי ההשפעה: לאחר שהבנו מה למדוד וגיבשנו את קטגוריות ונושאי ההשפעה, התמקדנו בשאלה כיצד למדוד? לצורך כך, באמצעות ניתוח ספרות ופניה למדדים מהעולם, גובשו 40 שאלות למדידה של נושאי ההשפעה שלכל אחד מהם ניתן ניקוד.</li>
                    <li style={{ marginTop: "1.5rem" }}>ניקוד: לצורך גיבוש המשקולות, בחנו את הראיונות שערכנו באמצעות מתודולוגיית Top of mind שלפיה ישנו ערך לאופן בו מנוסחות תשובות המרואיינים, ובפרט לתכנים שעולים ראשונים בתשובות, וגיבשנו רשימת משקולות כמפורט בתרשים בהמשך.</li>
                    <li style={{ marginTop: "1.5rem" }}>פיילוט: לאחר פיתוח סט שאלות, קביעת ניקוד ובניית אבטיפוס לכלי, ערכנו פיילוט לשיפור ודיוק הכלי. במסגרת זו קיבלנו משוב משמונה משקיעי אימפקט ויזמים בתחום ההתחדשות העירונית אשר השיבו על השאלון. בפיילוט ביקשנו התייחסות להיבטים שונים, החל מהיבטים טכניים כמו משך הזמן הנדרש למילוי השאלון, זמינות התכנים וכדומה, עובר בהיבטים הנוגעים לתוכן ולרלוונטיות של הכלי למשקיעי אימפקט ועד התייחסות לתוצר אותו מקבל המשקיע או היזם לאחר מילוי השאלון.</li>
                </ol>
            </div>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>מה מודד הכלי?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(2)} ref={el => arrowBox.current[2] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[2] = el}>
                <p>הכלי מאמץ נקודת מבט רחבה ורב תחומית על תהליכי התחדשות עירונית ובוחן היבטים מגוונים, בחלוקה לעשר קטגוריות אשר זוהו בתהליך העבודה כתחומים החברתיים והסביבתיים החשובים ביותר עליהם יש לתת את הדעת בהתחדשות עירונית:</p>
                <div style={{ backgroundImage: `url(${Thirdimg})`, height: "18em", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain" }}></div>
                <p>הכלי בוחן הן את האימפקט הישיר הצפוי במסגרת הפרויקט, כמו למשל שיפור איכות הדיור או ביצוע הליכי שיתוף ציבור, והן מאפיינים רחבים יותר של הפרויקט - שאינם קיימים בהכרח בזכות הפרויקט אך משפיעים על איכות החיים בפרויקט ובסביבתו - כמו למשל, מיקום הפרויקט בקרבת תחבורה ציבורית, שירותי רפואה ועוד.</p>
            </div>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>מי הגורם המבצע את ההערכה?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(3)} ref={el => arrowBox.current[3] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[3] = el}>
                <p>הכלי עוצב עבור משקיע אימפקט, ויחד עם זאת, מומלץ שמילוי השאלון בפועל יעשה על ידי היזם, מתוך הנחה שלו יש את מירב המידע על הפרויקט. מילוי השאלון יכול להעשות לבקשת משקיע כחלק מבחינת השקעה, או ביוזמת יזם אשר מבקש לעניין משקיע אימפקט בפרויקט מסוים.</p>
                <div style={{ backgroundImage: `url(${fourthimg})`, height: "12em", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain" }}></div>
                <p>לאחר מילוי השאלון היזם מקבל דו"ח עם ציון כולל וכן ציון מפורט המתייחס בנפרד לביצועי הפרויקט ביחס לכל קטגוריה בכלי. הדו"ח ישמש את היזם כדי להציג את האימפקט של הפרויקט בפני משקיעים.</p>
            </div>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>כיצד הכלי עובד בפועל?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(4)} ref={el => arrowBox.current[4] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[4] = el} >
                <p>הכלי מורכב משאלון אינטרנטי ובו 40 שאלות, לצד כל שאלה פרוט בנוגע למקור הנתונים אליו ניתן לפנות כדי להשיב על השאלה, התייחסות למגבלות שיכולות להיות בפני מענה על השאלה, כמו השלב בו מצוי הפרויקט או סוג התכנית שבה מקודמת התחדשות עירונית. לאחר מילוי השאלון מתקבל דוח ובו ציון כללי על הפרויקט הנבדק, וכן ציון של הפרויקט ביחס לכל קטגוריה.</p>
            </div>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>אלו פרויקטים ניתן להעריך באמצעות הכלי?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(5)} ref={el => arrowBox.current[5] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[5] = el}>
                <p>הכלי מכוון להערכת פרויקטים מגוונים של התחדשות עירונית, ובכלל זה מיזמים של פינוי - בינוי ופרויקטים רחבי היקף המבוצעים במסגרת תמ"א 38. ככלל, הכלי מותאם במיוחד לפרויקטים גדולים בעלי השפעה פיזית וחברתית משמעותית, ואנו ממליצים לעשות בו שימוש בפרויקטים הכוללים לפחות 100 יחידות דיור במצב הקיים.</p>
                <p>לשם קבלת הערכה מיטבית רצוי לבחון פרויקטים אשר גיבשו מתווה עסקי ותכנוני, ומצויים לפחות בשלבים הראשונים של פעילות בקהילה וגיבוש הסכמות למתווה תכנוני מוצע.
                    הערכת האימפקט באמצעות הכלי נועדה לשקף את האימפקט הצפוי של הפרויקט בנקודת זמן אחת - במועד ביצוע ההערכה - על מנת לסייע למשקיע לקבל החלטה ביחס להשקעה. הכלי בוחן פרויקטים בשלבי תכנון טרום ביצוע, והוא נבדל ממדדים אשר בוחנים תוצאות ארוכות טווח של פרויקטים (למשל באמצעות סקרים לאורך השנים).
                </p>
            </div>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>מהי רמת המומחיות הנדרשת לביצוע הערכה ומקור הנתונים לתשובות?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(6)} ref={el => arrowBox.current[6] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[6] = el}>
                <p>אחד העקרונות המנחים בפיתוח הכלי הוא יצירת הערכה פשוטה וזולה. ובכל זאת, מתוך 40 שאלות ישנן כאלה הדורשות רמות מקצועיות והשקעה שונה כדי לבצע את ההערכה. בחוברת הכלי תמצאו לצד כל שאלה פירוט של רמת המומחיות הנדרשת לביצוע ההערכה, על סקאלה שבין רמת מומחיות נמוכה מאוד לגבוהה מאוד. הערכת רמת המומחיות נקבעה בהתאם לזמינות ולמורכבות המידע הנדרש כדי להתייחס לשאלה. אנו מעריכים שמרבית היזמים יוכלו להשיב בקלות על שאלות המסווגות ברמת מומחיות נמוכה מאד, נמוכה ובינונית ואלו כדי להשיב על שאלות ברמת מומחיות גבוה וגבוהה מאד יהיה לצורך בפניה לגורם מקצועי המעורב בפרויקט כגון אדריכל, מתכנן או יועץ אחר.</p>
                <div style={{ backgroundImage: `url(${fifthimg})`, height: "24em", backgroundRepeat: "no-repeat", backgroundPosition: "right", backgroundSize: "contain" }}></div>
            </div>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>מהו הניקוד של כל קטגוריה?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(9)} ref={el => arrowBox.current[9] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[9] = el}>
                <p>הניקוד מבוסס על משקולות שניתנו בתהליך הפיתוח לכל אחת מעשר הקטגוריות, כמפורט בתרשים שלהלן. לכל קטגוריה יש משקל שונה בציון הכולל. הניקוד מבוצע באופן אוטומטי במסגרת השאלון האינטרנטי, ועל מנת לקבל ציון יש להשיב על כל השאלות.</p>
                <p>ישנן שאלות שבהן פרויקטים מסוימים מקבלים ניקוד אוטומטי – כך למשל, פרויקטים שכוללים הריסה ובניה מקבלים ניקוד אוטומטי בחלק מהשאלות הקשורות לשיפור איכות המערכות הטכניות בבית המשותף.</p>
                <div style={{ backgroundImage: `url(${siximg})`, height: "24em", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain" }}></div>
                <div style={{ backgroundImage: `url(${sevenimg})`, height: "24em", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain" }}></div>
            </div>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>ציון יחודי לפרויקט הממוקם בפריפריה</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(7)} ref={el => arrowBox.current[7] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[7] = el}>
                <p>הביקורת המרכזית על מדיניות ההתחדשות העירונית בישראל מתייחסת להעדר תהליכים אלה בפריפריה הגיאוגרפית והחברתית – כלכלית. באזורים פריפריאלים בהם ערכי הקרקע נמוכים, כמעט ולא מתרחשים תהליכי התחדשות עירונית, להוציא פרויקטים ספורים בתמיכה ממשלתית. כיוון שלמיזם התחדשות עירונית בפריפריה יש ערך חברתי רב, עצם המיקום של פרויקט בפריפריה זוכה לניקוד נוסף של 10 נקודות בתחשיב הציון.</p>
            </div>
            <hr />
            <div style={{ display: 'flex', padding: '0px 5rem', alignItems: 'center', justifyContent: 'space-between' }}>
                <h4>מיהו פרויקט בפריפריה?</h4>
                <img src={arrow} style={{ width: "3rem", height: "3rem", transform: 'rotate(0deg)', transition: 'transform 400ms', cursor: "pointer" }} onClick={() => box(8)} ref={el => arrowBox.current[8] = el} />
            </div>
            <div style={collapseStyle} ref={el => infoBox.current[8] = el}>
                <p>לצורך הכלי אנו מגדירים פרויקט באזור פריפריאלי כפרויקט הממוקם באזור המוגדר כאשכול חברתי כלכלי 1-6 בדרוג האשכולות החברתיים כלכליים של הלשכה המרכזית לסטטיסטיקה. על מנת לברר מהו האשכול החברתי של האזור הסטטיסטי בו מתקיים הפרויקט יש ראשית לזהות מהו מספר האזור הסטטיסטי בו מבוצע הפרויקט. ניתן לאתר מידע זה בקלות באתר Govmap באמצעות שכבת מידע של אזורים סטטיסטיים. לאחר זיהוי מספר האזור הסטטיסטי יש לבדוק מהו האשכול אליו משתייך האזור בסקר הלשכה המרכזית לסטטיסטיקה. פעולות אלו מבוצעות באופן אוטומטי בעת מילוי השאלון באתר הכלי.</p>
            </div>
            <hr />


            <div className={infoStyle.about}>
                <div className={infoStyle.hagarimg}></div>
                <p>הגר - מחקר ופיתוח דיור חברתי הוא גוף אוניברסיטאי שהוקם בפקולטה למשפטים באוניברסיטת תל אביב
                    על מנת לפתח ידע וכלים חדשים בתחום הדיור וההתחדשות העירונית ולקדם מדיניות שתאפשר דיור הולם,
                    נגיש ובהישג יד לבעלי הכנסה בינונית ונמוכה. הגר עוסק במחקר אקדמי יישומי בתחום של דיור בהישג יד
                    והתחדשות עירונית, בליווי פרויקטים 'מחוללי שינוי', בגיבוש הצעות חקיקה ופיתוח כלי מדיניות חדשים.
                    הגר פועל בראייה רב-תחומית ומעסיק אנשי מקצוע מתחום המשפט, התכנון, והכלכלה.
                </p>
            </div>
            <div className={infoStyle.about}>
                <div className={infoStyle.SFIimg}></div>
                <p>
                    Social Finance Israel הינה חברה לתועלת הציבור אשר הוקמה בשנת 2013 במטרה לקדם את כלכלת
                    האימפקט בישראל. SFI פועלת לפתרון אתגרים חברתיים, בריאותיים וסביבתיים בישראל דרך שימוש
                    במימון חדשני שהוא השקעות אימפקט. באמצעות צוות של אנשי מקצוע מנוסים ממגוון רקעים חברתיים
                    ופיננסיים SFI מלווה משקיעים חברתיים, קרנות פילנתרופיות, יזמים, ממשלות וארגונים בארץ ובעולם,
                    המעוניינים בהשגת תוצאות חברתיות מדידות ובתשואה חברתית לצד התשואה הכלכלית.
                </p>
                <p>יחד עם אחיותינו
                    ברחבי העולם SFI אחראית לפיתוח והוצאתן לפועל של אגרות חוב חברתיות, שמקדמות חדשנות בנושאים
                    חברתיים רבים, תוך יצירת תשואות חברתיות וכלכליות למשקיעים. SFI מרכזת את פעילות ´הפורום הישראלי
                    לקידום כלכלת האימפקט ´אשר חברים בו בכירים מן המגזר החברתי, הציבורי, והפיננסי.
                </p>
            </div>
            <a href="https://proceed-ltd.com/" target="_blank"><div className={infoStyle.thnx1}></div></a>
            {/* <div className={infoStyle.thnx2}></div> */}
        </div>
    </div >)
}