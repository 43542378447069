import { useNavigate } from "react-router-dom";
import { ReactComponent as HomeLogo } from '../SVG/home_logo.svg'
import { ReactComponent as Categories } from '../SVG/categories.svg'
import { ReactComponent as Steps } from '../SVG/steps.svg'
import Result from '../SVG/result.svg'
import { ReactComponent as Reason1 } from '../SVG/reason1.svg'
import { ReactComponent as Reason2 } from '../SVG/reason2.svg'
import { ReactComponent as Reason3 } from '../SVG/reason3.svg'
import { ReactComponent as Reason4 } from '../SVG/reason4.svg'
import homeStyle from './Home.module.css'
export default function Hone() {
    const navigate = useNavigate()

    return <div style={{ fontFamily: "Assistant-Bold, Assistant" }}>
        <div className="home_logo">
            <HomeLogo />
        </div>
        <div className={homeStyle.mainBody}>
            <div className="ctegory"  >
                <Categories />
            </div>
            <div className={homeStyle.firstArticle}>
                <p >
                    לפניך כלי חדש שנועד לסייע לך להעריך את האימפקט החברתי והסביבתי של פרויקט התחדשות עירונית.
                    <br />
                    פיתוח הכלי הוא חלק ממגמה שהולכת וצוברת תאוצה בעולם של שילוב השקעות אימפקט בהתחדשות עירונית.
                    <br />
                    <br />
                    הכלי מיועד לכל מי שמעוניין להעריך את ההשפעה החברתית והסביבתית העתידית של מיזם התחדשות עירונית, ובעיקר למשקיעים שמבצעים בדיקת נאותות לפרויקט כחלק מבחינת פרויקט להשקעה. כמו כן עשויים להסתייע בכלי יזמים, יועצי השקעות, גורמים ממשלתיים ועירוניים וארגונים שונים המעוניינים לאמוד אימפקט של פרויקטים של התחדשות עירונית.
                </p>
            </div>
            <div className={homeStyle.howTo}>
                <h2>איך עובדים עם הכלי?</h2>
                <hr />
                <p>הכלי מורכב משאלון אינטרנטי שבסופו תקבל\י דו"ח אימפקט ובו הערכה של האימפקט הפוטנציאלי של המיזם אותו בחנת על החברה ועל הסביבה.<br />
                    השאלון מורכב מ-40 שאלות והזמן המשוער למענה הוא כ-45 דקות.</p>
            </div>
            <div className={homeStyle.navigate}>
                <button onClick={() => navigate("/register")} >לשאלון לחצו כאן</button>
                <button onClick={() => navigate("/guid")} >למדריך למילוי השאלון</button>
            </div>
            <div className={homeStyle.article} >
                <h2>שלבי התהליך</h2>
                <hr />
                <Steps />
            </div>
            <div className={homeStyle.article}>
                <h2>מה ניתן לראות בדו"ח אימפקט למשקיע?</h2>
                <hr />
                <div style={{ backgroundImage: `url(${Result})`, backgroundSize: 'contain', height: '50rem', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
            </div>
            <div className={homeStyle.reasons}>
                <h2 >למה להעריך אימפקט של פרויקט התחדשות עירונית?</h2>
                <hr />
                <div >
                    <Reason1 style={{ width: "70px" }} />
                    <div >
                        <h3 >ליצור בידול איכותי:</h3>
                        <span>בשוק רווי הזדמנויות השקעה, התייחסות לאימפקט יוצרת בידול איכותי בהשוואה לפרויקטים ולהשקעות אחרים</span>
                    </div>
                </div>
                <hr />
                <div >
                    <Reason2 style={{ width: "70px" }} />
                    <div >
                        <h3 >לשפר תהליכי קבלת החלטות:</h3>
                        <span>שילוב של שיקולים סביבתיים וחברתיים מוביל להחלטות מושכלות יותר ומסייע למקבלי החלטות להימנע מסיכונים פוטנציאלים</span>
                    </div>
                </div>
                <hr />
                <div >
                    <Reason3 style={{ width: "70px" }} />
                    <div >
                        <h3>להציג שורת רווח כפולה:</h3>
                        <span>לקדם פרויקטים שיש בהם איכויות שלקוחות מעריכים, כמו סביבה ירוקה וקהילה מלוכדת, ולהגביר את הביקוש לפרויקט</span>
                    </div>
                </div>
                <hr />
                <div >
                    <Reason4 style={{ width: "70px" }} />
                    <div >
                        <h3 >להיות פורצי דרך:</h3>
                        <span>בתחום השקעות האימפקט שנמצא בהתהוות בישראל, לעצב את האקוסיסטם הישראלי ולהיות צעד לפני כולם</span>
                    </div>
                </div>
            </div>
        </div>
    </div >


}