// import { Doughnut } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// ChartJS.register(ArcElement, Tooltip, Legend);

export default function ProgresBar({ bgcolor, totualScor, score }) {
    let completed = ((score / totualScor) * 100).toFixed(1)
    const containerStyles = {
        display: 'flex',
        width: "90%",
        height: '100%',
        backgroundColor: 'rgb(224, 224, 222)',
        margin: '0.5rem 0rem',
        flexDirection: 'column-reverse'
    }

    const fillerStyles = {
        width: '100%',
        height: `${completed}%`,
        backgroundColor: bgcolor,
        textAlign: 'center'
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold',
    }
    return <div style={containerStyles}>
        <div style={fillerStyles}>
            <span style={labelStyles}>
                {`${completed}%`}</span>
        </div>
    </div>

}