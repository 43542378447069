import { Link, useLocation } from 'react-router-dom'
// import headerStyle from './header.module.css'
// import { useContext } from 'react'
import { ReactComponent as Nav } from '../../SVG/Navlogo.svg'
// import navBar from 'C:/Users/izika_000/Desktop/new_city/פרוייקט כלי התחדשות עירונית/עיצוב/Nav.svg'




export default function Header() {
    const location = useLocation()

    const activeStyle = {
        textDecoration: 'none',
        width: '19rem',
        height: '3rem',
        textAlign: 'center',
        borderBottom: '0.4rem solid #6eabf6'
    }
    const style = {
        textDecoration: 'none',
        width: '19rem',
        height: '3rem',
        textAlign: 'center'
    }

    return <div>
        <a href='https://www.israhc.org/' target='_blank'><Nav style={{ width: "100%" }} /></a>
        <nav style={{
            display: 'flex',
            fontSize: '27px',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Link style={(location.pathname === "/") ? activeStyle : style} to="/">דף הבית</Link>
            <Link style={(location.pathname === "/info") ? activeStyle : style} to="/info">אודות</Link>
            <Link style={(location.pathname === "/guid") ? activeStyle : style} to="/guid">מדריך למילוי השאלון</Link>
            <Link style={((location.pathname === "/servory") || (location.pathname === "/login") || (location.pathname === "/register")) ? activeStyle : style} to="/register">לשאלון לחץ כאן</Link>
        </nav>
    </div >
}

// function Login() {

//     if (ServoryID === '')
//         return <NavLink to={'/register'} className={headerStyle.headerLink}>רישום/התחברות</NavLink>
//     else <>
//         סקר מספר: {ServoryID}
//         <button onClick={logout}>התנתק</button>
//     </>
// }